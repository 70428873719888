import {
    LOAD_BLACKBOOK_PENDING,
    GET_BLACKBOOK_VALUES, 
    FETCH_BLACKBOOK_VALUES, 
    CLEAR_BLACKBOOK_VALUES,
    CLEAR_ALL } from "constants/actions";
const initialState = {
    blackbook: [],
    selected: false,
    pending: false,
};

export default  function Blackbook(state = initialState, action) {
    switch (action.type) {
        case LOAD_BLACKBOOK_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_BLACKBOOK_VALUES:
            return {
                ...state,
                blackbook: action.payload,
                pending: false,
            };
        case FETCH_BLACKBOOK_VALUES:
            return { ...state, 
                selected: action.payload,
                pending: false, };

        case CLEAR_BLACKBOOK_VALUES:
            return {
                ...state,
                blackbook: [],
                pending: false,
            };
        case CLEAR_ALL:
            return {
                ...state,
                blackbook: [],
                selected: false,
                pending: false,
            };
        default:
            return state;
    }
}
