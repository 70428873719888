import React from "react";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// reactstrap components
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Collapse,
  // Label,
  FormGroup,
  Container,
} from "reactstrap";

import { LeftArrow } from "utilities/SocialIcons";

import Select from "react-select";
import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

var SelectResidenceList = [
  { value: 1, label: "Rented" },
  { value: 2, label: "Owned" },
  { value: 3, label: "Other" },
];

let years = [];
_.times(20, (i) => {
  years.push({ value: i, label: `${i}` });
});

let months = [];
_.times(11, (i) => {
  months.push({ value: i, label: `${i}` });
});

var postal_code_validator = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

const Residence = ({ setDirection, setInSection  }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    control,
  } = useForm();

  const [residence, setResidence] = React.useState([]);
  const [ownedIsOpen, setOwnedIsOpen] = React.useState(false);
  const [residenceType, setResidenceType] = React.useState(false);
  const [yearType, setYearType] = React.useState(false);
  const [monthType, setMonthType] = React.useState(false);

  const [mortgage, setMortgage] = React.useState("Rent");
  const [prevMortgage, setPrevMortgage] = React.useState("Rent");

  const [showPrevResidence, setShowPrevResidence] = React.useState(false);

  const [prevResidenceType, setPrevResidenceType] = React.useState(null);
  const [prevYearType, setPrevYearType] = React.useState(null);
  const [prevMonthType, setPrevMonthType] = React.useState(null);

  const { get_residence } = useSelector(
    (state) => ({
      get_residence: state.application.application,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (_.has(get_residence, "residence")) {
      setResidence(get_residence.residence);
      setResidenceType(get_residence.residence.residence_type);
      
      setYearType(get_residence.residence.years_in_home);
      setMonthType(get_residence.residence.months_in_home);
      setPrevResidenceType(get_residence.residence.prev_residence_type);

      setPrevYearType(get_residence.residence.prev_years_in_home);
      setPrevMonthType(get_residence.residence.prev_months_in_home);
    }
  }, [get_residence]);

  const onResidenceChange = (selectedOption) => {
    // console.log(`Option selected:`, selectedOption[0].value);
    setResidenceType(selectedOption[0]);
    if (selectedOption[0].value === 2) {
      setOwnedIsOpen(true);
      setMortgage("Mortgage");
    } else {
      setOwnedIsOpen(false);
      setMortgage(selectedOption[0].value === 1 ? "Rent" : "Payout");
    }
  };

  const onPrevResidenceChange = (selectedOption) => {
    setPrevResidenceType(selectedOption[0]);
    if (selectedOption[0].value === 2) {
      setPrevMortgage("Mortgage");
    } else {
      setPrevMortgage(selectedOption[0].value === 1 ? "Rent" : "Payout");
    }
  };

  React.useEffect(() => {
    if (residenceType.value === 2) {
      setOwnedIsOpen(true);
      setMortgage("Mortgage");
    } else {
      setOwnedIsOpen(false);
      setMortgage(
        residenceType.value === 1 ? "Rent" : "Payout"
      );
    }
  }, [residenceType])

  React.useEffect(() => {
    if (prevResidenceType) {
       if (prevResidenceType.value === 2) {
      setPrevMortgage("Mortgage");
    } else {
      setPrevMortgage(
        prevResidenceType.value === 1 ? "Rent" : "Payout"
      );
    }
    }
  }, [prevResidenceType])

  const onYearsChange = (e) => {
    if (e[0].value < 2) {
      setShowPrevResidence(true);
    } else {
      setShowPrevResidence(false);
    }
    setYearType(e[0]);
  };

  React.useEffect(() => {
    if (yearType) {
       if (yearType.value < 2) {
        setShowPrevResidence(true);
    } else {
      setShowPrevResidence(false);
    }
    }
   
  }, [yearType])

  const onPrevYearsChange = (e) => {
    setPrevYearType(e[0]);
  };

  const onMonthsChange = (e) => {
    setMonthType(e[0]);
  };

  const onPrevMonthsChange = (e) => {
    setPrevMonthType(e[0]);
  };

  React.useEffect(() => {
    //
    setValue("residence_type", residenceType);
    setValue("years_in_home", yearType);
    setValue("months_in_home", monthType);
    // Previous
    setValue("prev_residence_type", prevResidenceType);
    setValue("prev_years_in_home", prevYearType);
    setValue("prev_months_in_home", prevMonthType);
  }, [
    setValue,
    residenceType,
    yearType,
    monthType,
    prevResidenceType,
    prevYearType,
    prevMonthType,
  ]);

  React.useEffect(() => {
    register(
      { name: "residence_type" },
      { required: "Please select a Source of Income" }
    );
    register(
      { name: "years_in_home" },
      { required: `Please select years in Residence` }
    );
    register(
      { name: "months_in_home" },
      { required: `Please select months in Residence` }
    );

    if (showPrevResidence) {
      register(
        { name: "prev_residence_type" },
        { required: "Please select a Source of Previous Income" }
      );
      register(
        { name: "prev_years_in_home" },
        { required: `Please select years in Previous Residence` }
      );
      register(
        { name: "prev_months_in_home" },
        { required: `Please select months in Previous Residence` }
      );
    } else {
      register({ name: "prev_address" }, { required: false });
      register({ name: "prev_city" }, { required: false });
      register({ name: "prev_postcode" }, { required: false });
      register({ name: "prev_monthly_rent" }, { required: false });
      register({ name: "prev_residence_type" }, { required: false });
      register({ name: "prev_months_in_home" }, { required: false });
      register({ name: "prev_years_in_home" }, { required: false });
    }
  });

  const onSubmit = (data) => {
    // console.log(data)

    dispatch({
      type: SG_UPDATE_FINANCE_APPLICATION,
      payload: {
        residence: {
          ...data,
          residence_type: residenceType,
          years_in_home: yearType,
          months_in_home: monthType,
          prev_residence_type: prevResidenceType,
          prev_years_in_home: prevYearType,
          prev_months_in_home: prevMonthType,
        },
      },
    });
    setDirection(true);
    setInSection(6);
  };
  //
  function GoBack() {
    setDirection(false);
    setInSection(4);
  }

  //
  return (
    <>
    <Container>
        <LargeContainer>
          <Button close onClick={GoBack} />
          <Row className="justify-content-center text-center">
            <Col md="8">
              <h2 className="display-4">
                <span style={{ cursor: "pointer" }}>
                <LeftArrow onClick={GoBack} />{" "}
                </span>
                Residence Information
              </h2>

              <p>Some information to help us complete the form.</p>
            </Col>
          </Row>
      

          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <p>
                Residence Type <span className="icon-danger">*</span>
              </p>

              <Controller
                as={Select}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                options={SelectResidenceList}
                value={residenceType ? residenceType : null}
                name="residence_type"
                className="text-dark"
                control={control}
                onChange={onResidenceChange}
              />
              <p className="text-danger small">
                {residenceType
                  ? ""
                  : errors.residence_type && (
                      <span>{errors.residence_type.message}</span>
                    )}
              </p>
            </FormGroup>

            <FormGroup>
              <p>
                Address <span className="icon-danger">*</span>
              </p>
              <Input
                name="address"
                className="border-input"
                defaultValue={residence? residence.address : null }
                innerRef={register({
                  required: "Please give the Residence Address",
                })}
                placeholder="Address"
                type="text"
              />
              <p className="text-danger small">
                {errors.address && <span>{errors.address.message}</span>}
              </p>
            </FormGroup>
            <Row>
              <Col md="6">
                <FormGroup>
                  <p>
                    City <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    defaultValue={residence? residence.city : null }
                    name="city"
                    placeholder="City"
                    innerRef={register({
                      required: "Please enter City",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.city && <span>{errors.city.message}</span>}
                  </p>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <p>
                    Postal Code <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    name="postcode"
                    defaultValue={residence? residence.postcode : null }
                    placeholder="Postal Code"
                    innerRef={register({
                      required: "Please enter Postal Code",
                      pattern: {
                        value: postal_code_validator,
                        message: "invalid postal code",
                      },
                    })}
                  />
                  <p className="text-danger small">
                    {errors.postcode && <span>{errors.postcode.message}</span>}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <p>
                Phone <span className="icon-danger">*</span>
              </p>
              <Input
                className="border-input"
                name="phone"
                defaultValue={residence? residence.phone : null }
                placeholder="Home Phone Number"
                type="text"
                innerRef={register({
                  required: "Please give the Home Phone Number",
                })}
              />
              <p className="text-danger small">
                {errors.phone && <span>{errors.phone.message}</span>}
              </p>
            </FormGroup>
            <FormGroup>
              <p>
                Monthly {mortgage} <span className="icon-danger">*</span>
              </p>
              <Input
                name="monthly_outgo"
                className="border-input"
                placeholder={mortgage}
                defaultValue={residence? residence.monthly_outgo : null }
                innerRef={register({
                  required: `Please give the ${mortgage} amount`,
                })}
                type="text"
              />
              <p className="text-danger small">
                {errors.monthly_outgo && (
                  <span>{errors.monthly_outgo.message}</span>
                )}
              </p>
            </FormGroup>

            {/* Owned */}
            <Collapse isOpen={ownedIsOpen}>
              <FormGroup>
                <p>
                  Owners Name <span className="icon-danger">*</span>
                </p>
                <Input
                  name="owners_name"
                  className="border-input"
                  placeholder="Owners Name"
                  defaultValue={residence? residence.owners_name : null }
                  innerRef={register({
                    required: ownedIsOpen
                      ? `Please give the owners name`
                      : false,
                  })}
                  type="text"
                />
                <p className="text-danger small">
                  {errors.owners_name && (
                    <span>{errors.owners_name.message}</span>
                  )}
                </p>
              </FormGroup>
              <FormGroup>
                <p>
                  Current Market Value <span className="icon-danger">*</span>
                </p>
                <Input
                  name="house_value"
                  defaultValue={residence? residence.house_value : null }
                  className="border-input"
                  placeholder="Market Value"
                  innerRef={register({
                    required: ownedIsOpen
                      ? `Please give the current market 
                     value of the residence`
                      : false,
                  })}
                  type="text"
                />
                <p className="text-danger small">
                  {errors.house_value && (
                    <span>{errors.house_value.message}</span>
                  )}
                </p>
              </FormGroup>
            </Collapse>
            {/* Owned */}

            <Row>
              <Col md="6">
                <FormGroup>
                  <p>
                    Years in Residence
                    <span className="icon-danger">*</span>
                  </p>
                  <Controller
                    as={Select}
                    options={years}
                    name="years_in_home"
                    className="text-dark"
                    control={control}
                    onChange={onYearsChange}
                    //
                  />
                  <p className="text-danger small">
                    {yearType
                      ? ""
                      : errors.years_in_home && (
                          <span>{errors.years_in_home.message}</span>
                        )}
                  </p>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <p>
                    Months in Residence
                    <span className="icon-danger">*</span>
                  </p>
                  <Controller
                    as={Select}
                    options={months}
                    name="months_in_home"
                    className="text-dark"
                    control={control}
                    onChange={onMonthsChange}
                    //
                  />
                  <p className="text-danger small">
                    {monthType
                      ? ""
                      : errors.months_in_home && (
                          <span>{errors.months_in_home.message}</span>
                        )}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            {/* Previous Residence */}
            <Collapse isOpen={showPrevResidence}>
              <h6>Previous Residence</h6>
              <small>{`Since you have been living here for less than 
                    2 years we need some more information`}</small>
              <hr />
              <FormGroup>
                <p>
                  Previous Residence Type <span className="icon-danger">*</span>
                </p>

                <Controller
                  as={Select}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option.value}`}
                  options={SelectResidenceList}
                  value={prevResidenceType ? prevResidenceType : null}
                  name="prev_residence_type"
                  className="text-dark"
                  control={control}
                  onChange={onPrevResidenceChange}
                />
                <p className="text-danger small">
                  {prevResidenceType
                    ? ""
                    : errors.prev_residence_type && (
                        <span>{errors.prev_residence_type.message}</span>
                      )}
                </p>
              </FormGroup>

              <FormGroup>
                <p>
                  Address <span className="icon-danger">*</span>
                </p>
                <Input
                  name="prev_address"
                  className="border-input"
                  defaultValue={residence? residence.prev_address : null }
                  innerRef={register({
                    required: "Please give the Previous Address",
                  })}
                  placeholder="Previous Address"
                  type="text"
                />
                <p className="text-danger small">
                  {errors.prev_address && (
                    <span>{errors.prev_address.message}</span>
                  )}
                </p>
              </FormGroup>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <p>
                      Previous City <span className="icon-danger">*</span>
                    </p>
                    <Input
                      className="border-input"
                      name="prev_city"
                      defaultValue={residence? residence.prev_city : null }
                      placeholder="City"
                      innerRef={register({
                        required: "Please enter Previous City",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.prev_city && (
                        <span>{errors.prev_city.message}</span>
                      )}
                    </p>
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <p>
                      Previous Postal Code{" "}
                      <span className="icon-danger">*</span>
                    </p>
                    <Input
                      className="border-input"
                      name="prev_postcode"
                      defaultValue={residence? residence.prev_postcode : null }
                      placeholder="Postal Code"
                      innerRef={register({
                        required: "Please enter Postal Code",
                        pattern: {
                          value: postal_code_validator,
                          message: "invalid postal code",
                        },
                      })}
                    />
                    <p className="text-danger small">
                      {errors.prev_postcode && (
                        <span>{errors.prev_postcode.message}</span>
                      )}
                    </p>
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <p>
                  Monthly {prevMortgage} <span className="icon-danger">*</span>
                </p>
                <Input
                  name="prev_monthly_rent"
                  className="border-input"
                  placeholder={mortgage}
                  defaultValue={residence? residence.prev_monthly_rent : null }
                  innerRef={register({
                    required: `Please give the previous ${prevMortgage} amount`,
                  })}
                  type="text"
                />
                <p className="text-danger small">
                  {errors.prev_monthly_rent && (
                    <span>{errors.prev_monthly_rent.message}</span>
                  )}
                </p>
              </FormGroup>

              <Row>
                <Col md="6">
                  <FormGroup>
                    <p>
                      Years in Residence
                      <span className="icon-danger">*</span>
                    </p>
                    <Controller
                      as={Select}
                      options={years}
                      name="prev_years_in_home"
                      
                      className="text-dark"
                      control={control}
                      onChange={onPrevYearsChange}
                      //
                    />
                    <p className="text-danger small">
                      {prevYearType
                        ? ""
                        : errors.prev_years_in_home && (
                            <span>{errors.prev_years_in_home.message}</span>
                          )}
                    </p>
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <p>
                      Months in Residence
                      <span className="icon-danger">*</span>
                    </p>
                    <Controller
                      as={Select}
                      options={months}
                      name="prev_months_in_home"
                      className="text-dark"
                      control={control}
                      onChange={onPrevMonthsChange}
                      //
                    />
                    <p className="text-danger small">
                      {prevMonthType
                        ? ""
                        : errors.prev_months_in_home && (
                            <span>{errors.prev_months_in_home.message}</span>
                          )}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
            {/* Previous Residence */}
         
              <div className="d-flex justify-content-between">
                <div className="p-2">
                  <Button
                    className="btn"
                    color="secondary"
                    onClick={() => GoBack}
                  >
                    Prev
                  </Button>
                </div>
                <div className="p-2">
                  <Button className="btn" color="danger" type="submit">
                    Next
                  </Button>
                </div>
              </div>
         
          </Form>
          </LargeContainer>
      </Container>
    </>
  );
};

export default Residence;

const LargeContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  height: 1500px;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;

const BouncyDiv = styled.div`
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  // cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center; // left to right
  // border-color: #000000;
  // border-style: solid;
  // border-width: 1px;
  // &:hover {
  //   border-color: #ff0000;
  //   border-style: solid;
  //   border-width: 1px;
  // }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between; // left to right
`;
const MainContent = styled.div`
  padding: 5px;
  // min-height: 60px;
  background-color: #ffffff;
  margin: 5px;
  font-size: large;
`;

