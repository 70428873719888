import React from "react";

// reactstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Error404() {

  React.useEffect(() => {
    document.body.classList.add("error-404");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-404");
    };
  });
  return (
    <>

      <div
        className="background-img"
        // style={{
        //   backgroundImage:
        //     "url(" + require("assets/images/other-images/martin-knize.jpg") + ")"
        // }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col>
              <h1 className="title">
                404
                <br />
                <p className="error-msg">The page you requested could not be found</p>
              </h1>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  );
}

export default Error404;
