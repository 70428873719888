import {

  CLEAR_YEARS,
  ADD_YEARS,
  DELETE_YEARS,
  CLEAR_MODELS,
  ADD_MODELS,
  DELETE_MODELS,
  CLEAR_MAKES,
  DELETE_MAKES,
  ADD_MAKES,
  CLEAR_COLORS,
  ADD_COLORS,
  DELETE_COLORS,
  CLEAR_BODYTYPE,
  ADD_BODYTYPE,
  DELETE_BODYTYPE,
  CLEAR_KILOMETER,
  ADD_KILOMETERS,
  CLEAR_PRICE,
  ADD_PRICES,
  RESET_ALL_PARAM,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  years: [],
  makes: [],
  models: [],
  colors: [],
  bodytype: [],
  kilometers: [],
  prices: [],
  search: false,
};

export default function ResetPageParams (state = initialState, action) {
  switch (action.type) {
    // this is actually delete
    case DELETE_YEARS:

      return {
        ...state,
        years: state.years.filter((fl) => fl !== action.payload),
      };
    case DELETE_MAKES:
      return {
        ...state,
        makes: state.makes.filter((fl) => fl !== action.payload),
      };
    case DELETE_MODELS:
      return {
        ...state,
        models: state.models.filter((fl) => fl !== action.payload),
      };
    case DELETE_COLORS:
      return {
        ...state,
        colors: state.colors.filter((fl) => fl !== action.payload),
      };
    case DELETE_BODYTYPE:

      return {
        ...state,
        bodytype: state.bodytype.filter((fl) => fl !== action.payload),
      };
    case CLEAR_YEARS:
      return {
        ...state,
        years: false,
      };

    case CLEAR_MAKES:
      return {
        ...state,
        makes: false,
      };

    case CLEAR_MODELS:
      return {
        ...state,
        models: false,
      };

    case CLEAR_COLORS:
      return {
        ...state,
        colors: false,
      };

    case CLEAR_BODYTYPE:
      return {
        ...state,
        bodytype: false,
      };

    case CLEAR_KILOMETER:
      return {
        ...state,
        kilometers: false,
      };
    case CLEAR_PRICE:
      return {
        ...state,
        prices: false,
      };

    case ADD_YEARS:
        return {
          ...state,
          years: action.payload,
        };

    case ADD_MAKES:
      return {
        ...state,
        makes: action.payload,
      };

    case ADD_MODELS:
      return {
        ...state,
        models: action.payload,
      };

    case ADD_COLORS:
      return {
        ...state,
        colors: action.payload,
      };

    case ADD_BODYTYPE:
      return {
        ...state,
        bodytype: action.payload,
      };
    case ADD_KILOMETERS:
      return {
        ...state,
        kilometers: action.payload,
      };
    case ADD_PRICES:
      return {
        ...state,
        prices: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        years: [],
        makes: [],
        models: [],
        colors: [],
        bodytype: [],
        kilometers: [],
        prices: [],
        search: false,
      };

    case RESET_ALL_PARAM:
      return {
        ...state,
        years: [],
        makes: [],
        models: [],
        colors: [],
        bodytype: [],
        kilometers: [],
        prices: [],
        search: false,
      }
    default:
      return state;
  }
}
