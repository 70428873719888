import { 
    GET_TRIM_SELECT, 
    FETCH_TRIM_SELECT, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    trimselect: [],
    trimSelected:[]
};

export default function SelectTrim (state = initialState, action) {
    switch (action.type) {
        case GET_TRIM_SELECT:
            return {
                ...state,
                trimselect: action.payload
            };
        case FETCH_TRIM_SELECT:
            return { ...state, trimSelected: action.payload };
        case CLEAR_ALL:
            return {
                ...state,
                trimselect: []
            };
        default:
            return state;
    }
}

