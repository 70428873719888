import { put, all, call, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  ADD_WEBLEAD,
  CREATE_WEBLEAD,
  ADD_TRADEIN,
  CREATE_LEASE_BUSTER,
  ADD_LEASE_BUSTER,
  CREATE_TRADEIN,
  // ADD_APPOINTMENT,
  CREATE_CONTACT_US,
  ADD_CONTACT_US,
  CREATE_WEB_COMMENT,
  ADD_WEB_COMMENT,
  CREATE_APPOINTMENT,
  ADD_FINANCE_APPLICATION,
  ADD_APPOINTMENT,
  CREATE_FINANCE_APPLICATION,
  UPDATE_FINANCE_APPLICATION,
  SG_UPDATE_FINANCE_APPLICATION,
  CREATE_EMPLOYEE_TASK,
  ADD_EMPLOYEE_TASK,
  LOAD_WEB_LEAD_SOURCES,
  GET_WEB_LEAD_SOURCES,
  CLEAR_ALL,
  ERASE_ALL,
  ALL_ERRORS,
} from "constants/actions";



function* getWebLeads(action) {
  try {
    const json = yield call(api.GET_WEB_LEAD_SOURCE, action.payload);
    yield put({ type: GET_WEB_LEAD_SOURCES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* WebleadLookUp() {
  yield takeLatest(LOAD_WEB_LEAD_SOURCES, getWebLeads);
}

function* submitWebLeads(action) {
  try {
    const json = yield call(api.POST_WEB_LEADS, action.payload);
    yield put({ type: ADD_WEBLEAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* WebleadSubmit() {
  yield takeLatest(CREATE_WEBLEAD, submitWebLeads);
}

function* submitTradeins(action) {
  try {
    const json = yield call(api.POST_TRADEIN, action.payload);
    yield put({ type: ADD_TRADEIN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* TradeinSubmit() {
  yield takeLatest(CREATE_TRADEIN, submitTradeins);
}

function* submitLeaseBuster(action) {
  try {
    const json = yield call(api.POST_LEASEBUSTER, action.payload);
    yield put({ type: ADD_LEASE_BUSTER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* LeaseBusterSubmit() {
  yield takeLatest(CREATE_LEASE_BUSTER, submitLeaseBuster);
}

function* submitAppointments(action) {

  try {
    const json = yield call(api.POST_APPOINTMENT, action.payload);
    yield put({ type: ADD_APPOINTMENT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* AppointmentsSubmit() {
  yield takeLatest(CREATE_APPOINTMENT, submitAppointments);
}


function* submitContactUs(action) {

  try {
    const json = yield call(api.POST_CONTACTUS, action.payload);
    yield put({ type: ADD_CONTACT_US, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* ContactUsSubmit() {
  yield takeLatest(CREATE_CONTACT_US, submitContactUs);
}


function* submitComment(action) {

  try {
    const json = yield call(api.POST_WEB_COMMENTS, action.payload);
    yield put({ type: ADD_WEB_COMMENT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* CommentSubmit() {
  yield takeLatest(CREATE_WEB_COMMENT, submitComment);
}


function* submitEmployeeTask(action) {

  try {
    const json = yield call(api.POST_EMPLOYEE_TASKS, action.payload);
    yield put({ type: ADD_EMPLOYEE_TASK, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* employeeTaskSubmit() {
  yield takeLatest(CREATE_EMPLOYEE_TASK, submitEmployeeTask);
}
// ADD_FINANCE_APPLICATION
function* submitApplication(action) {

  try {
    const json = yield call(api.POST_CREDIT_APPLICATION, action.payload);
    yield put({ type: ADD_FINANCE_APPLICATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* ApplicationSubmit() {
  yield takeLatest(CREATE_FINANCE_APPLICATION, submitApplication);
}

function* updateApplication(action) {
  yield put({ type: UPDATE_FINANCE_APPLICATION, payload : action.payload });
}

export function* ApplicationUpdate() {
  yield takeLatest(SG_UPDATE_FINANCE_APPLICATION, updateApplication);
}
// ADD_FINANCE_APPLICATION

function* clearAll() {
  yield put({ type: CLEAR_ALL });
}

export function* AllClear() {
  yield takeLatest(ERASE_ALL, clearAll);
}

export default function* index() {
  yield all([
    WebleadLookUp(),
    WebleadSubmit(),
    TradeinSubmit(),
    LeaseBusterSubmit(),
    AppointmentsSubmit(),
    ApplicationSubmit(),
    ApplicationUpdate(),
    ContactUsSubmit(),
    CommentSubmit(),
    submitEmployeeTask(),
    AllClear(),
  ]);
}
