import React from "react";
// react plugin used to create google maps
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
// reactstrap components
import {
  Alert,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import LeadSource from "utilities/LeadSource";

import { CREATE_CONTACT_US } from "constants/actions";
import config from "_config";
const email_validation = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

function ContactUs() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();
  const [leadSource, SetLeadSource] = React.useState(LeadSource());
  const [visible, setVisible] = React.useState(false);

  const [userIp, setUserIp] = React.useState();

  React.useEffect(() => {
    SetLeadSource(LeadSource())
  }, []);
  
  React.useEffect(() => {
    if (!userIp) {
      async function fetchData() {
        const res = await fetch("https://api.ipify.org?format=json");
        res
          .json()
          .then((res) => {
            setUserIp(res.ip);
          })
          .catch((err) => console.log(err));
      }
      fetchData();
    }
  });

  const onSubmit = (data, e) => {
    dispatch({
      type: CREATE_CONTACT_US,
      payload: {
        first_name: data.first_name,
        last_name: data.last_name,
        email_id: data.emailiid,
        phone: data.phone,
        description: data.description,
        web_lead_stage: config.web_lead_stage,
        web_lead_type: config.web_lead_type_contact,
        web_lead_source: config.web_lead_source,
        campaign_code: leadSource.campaign_code,
        deviceType: leadSource.deviceType,
        gclid: leadSource.gclid,
        isBrowser: leadSource.isBrowser,
        isMobile: leadSource.isMobile,
        browser: leadSource.browser,
        lead_source: leadSource.lead_source,
        mssclid: leadSource.mssclid,
        osName: leadSource.osName,
        osVersion: leadSource.osVersion,
        pixelid: leadSource.pixelid,
        userIp: userIp,
        utm_campaign: leadSource.utm_campaign,
        utm_medium: leadSource.utm_medium,
        utm_source: leadSource.utm_source,
      },
    });
    setVisible(true);
    e.target.reset(); // HTML standard reset() function will only reset inputs' value
  };

  // console.log(errors)
  return (
    <>

        <div>
          <Alert
            color="success"
            isOpen={visible}
            toggle={() => setVisible(!visible)}
          >
            <p>Thanks for contacting us!</p>
          </Alert>
          <div className="plain-form">
            <Card className="text-dark card-plain">
              <CardBody>
                <h3 className="form-subheading">Contact Us</h3>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md="6">
                      <FormGroup className="label-floating">
                        <p>
                          First name <span className="icon-danger">*</span>
                        </p>
                        <Input
                          name="first_name"
                          placeholder="First Name"
                          type="text"
                          innerRef={register({
                            required: "Please enter First Name",
                          })}
                        />
                        <p className="text-danger small">
                          {errors.first_name && (
                            <span>{errors.first_name.message}</span>
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="label-floating">
                        <p>
                          Last name <span className="icon-danger">*</span>
                        </p>
                        <Input
                          name="last_name"
                          placeholder="Last Name"
                          type="text"
                          innerRef={register({
                            required: "Please enter Last Name",
                          })}
                        />
                        <p className="text-danger small">
                          {errors.last_name && (
                            <span>{errors.last_name.message}</span>
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <p>
                      Phone <span className="icon-danger">*</span>
                    </p>

                    {/* <Controller
                  as={<NumberFormat format="+1 (###) ###-####" 
                  mask="_" />}
                  control={control}
                  
                  className="border-input"
                  name="phone"
                  placeholder="enter the Phone Number"
                  type="text"
                  innerRef={register({
                    required: "Please enter phone number",
                    minlength: 10,
                  })}
                /> */}
                    <Input
                      className="border-input"
                      name="phone"
                      placeholder="enter the Phone Number"
                      type="text"
                      innerRef={register({
                        required: "Please enter phone number",
                        minlength: 10,
                      })}
                    />
                    <p className="text-danger small">
                      {errors.phone && <span>{errors.phone.message}</span>}
                    </p>
                  </FormGroup>

                  <FormGroup className="label-floating">
                    <p>
                      Email address <span className="icon-danger">*</span>
                    </p>
                    <Input
                      name="emailiid"
                      placeholder="Email"
                      type="email"
                      innerRef={register({
                        required: "Email is required",
                        pattern: {
                          value: email_validation,
                          message: "invalid email address",
                        },
                        maxlength: 20,
                      })}
                    />
                    <p className="text-danger small">
                      {errors.emailiid && (
                        <span>{errors.emailiid.message}</span>
                      )}
                    </p>
                  </FormGroup>
                  <FormGroup className="label-floating">
                    <p>
                      Your message <span className="icon-danger">*</span>
                    </p>
                    <Input
                      id="description"
                      name="description"
                      placeholder="Message"
                      type="textarea"
                      rows="6"
                      innerRef={register({
                        required: "Please enter Message",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.description && (
                        <span>{errors.description.message}</span>
                      )}
                    </p>
                  </FormGroup>

                  <FormGroup check>
                    <Label check>
                      <Controller
                        as={<Input type="checkbox" />}
                        name="agree"
                        value={true}
                        control={control}
                        defaultValue={false}
                        innerRef={register({
                          required: "Please Check this box",
                        })}
                      />
                      I agree to terms and conditions{" "}
                      <span className="form-check-sign" />
                    </Label>
                    <p className="text-danger small">
                      {errors.agree && <span>{errors.agree.message}</span>}
                    </p>
                  </FormGroup>
                  <Row>
                    <Col>
                      <Button color="danger" type="submit">
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </div>
        </div>

    </>
  );
}

export default ContactUs;
