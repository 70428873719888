import { ADD_CONTACT_US, CLEAR_ALL } from "constants/actions";

const initialState = {
    contactus: [],
    created: []
};

export default function ContactUs (state = initialState, action) {
    switch (action.type) {

        case ADD_CONTACT_US:
            return {
                ...state,
                created: action.payload
            };
       
        case CLEAR_ALL:
            return {
                ...state,
                contactus: []
            };
        default:
            return state;
    }
}

