import React from "react";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import ReactDatetime from "react-datetime";
// import { useHistory } from "react-router-dom";

// import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
// import SignatureCanvas from "react-signature-canvas";
// import styles from './signature.css'
import {
  // SG_UPDATE_FINANCE_APPLICATION,
  CREATE_FINANCE_APPLICATION,
} from "constants/actions";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  // CardTitle,
  CardFooter,
  Form,
  Input,
  Row,
  Container,
  Col,
  Label,
  FormGroup,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import { LeftArrow } from "utilities/SocialIcons";


const CreditCheck = ({ CompletedSection, setDirection, setInSection }) => {
  // let history = useHistory();

  const sigPad = React.useRef(null);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, reset, setValue } = useForm();
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 6570));
  const [application, setApplication] = React.useState([]);
  const [trimmedDataURL, setTrimmedDataURL] = React.useState()
  // const [major, setMajor] = React.useState();
  // React.useEffect(() => {
  //   register({ name: "year" }, { required: "Please select a Year" });
  // }, [register]);
  const { get_application } = useSelector(
    (state) => ({
      get_application: state.application.application,
    }),
    shallowEqual
  );

  function GoBack() {
    setDirection(false);
    setInSection(5);
  }

  React.useEffect(() => {
    setApplication(get_application);
  }, [get_application]);
  const major = ReactDatetime.moment().subtract(18, "year");
  var valid = function (current) {
    return current.isBefore(major);
  };

  React.useEffect(() => {
    if (startDate) {
      setValue("date_of_birth", moment(startDate).format("YYYY-MM-DD"));
    } else {
      setValue("date_of_birth", moment().subtract(18, "years"));
    }
  });
  const handleDateTimeChange = (e) => {
    // console.log(e)
    setStartDate(e[0]);
  };

  const ClearSignature = () => {
    sigPad.current.clear()
    setTrimmedDataURL()
  };

  const AcceptSignature = () => {
    setTrimmedDataURL(sigPad.current.getTrimmedCanvas().toDataURL('image/png'))

  };

  React.useEffect(() => {
    register(
      { name: "date_of_birth" },
      { required: "Please enter Date of Birth" }
    );

    if (moment.duration(major.diff(startDate)).days() < 0) {
      register(
        { name: "date_of_birth" },
        { required: "Please select Date less than 18 years" }
      );
    }
    // scheduled_for
  }, [register, startDate, major]);

  // console.log(get_application);
  const onSubmit = (data, e) => {
    // console.log(data);
    // console.log(get_application);
    dispatch({
      type: CREATE_FINANCE_APPLICATION,
      payload: {
        ...get_application.contact_info,
        ...get_application.lead_source,
        full_response: {
          employment: { ...get_application.employment },
          residence: { ...get_application.residence },
          credit_auth: { date_of_birth: startDate, ...data },
        },
      },
    });
    e.target.reset();
    CompletedSection(6)
    // history.push(`/inventory`)
  };
  //
  // console.log(moment.duration(major.diff(startDate)).days())
  // console.log(major)
  // console.log(startDate)

  return (
    <>
      <Container fluid>
        <LargeContainer>
          <Button close onClick={GoBack} />
          <Row className="justify-content-center text-center">
            <Col md="8">
              <h2 className="display-4">
                <span style={{ cursor: "pointer" }}>
                <LeftArrow onClick={GoBack} />{" "}
                </span>
                Authorize Credit Check
              </h2>

              <p>Final Checks to complete the application.</p>
            </Col>
          </Row>
          <Row className="align-items-center">
              <Col lg="12">

            <Card className="text-dark card-plain ">
              <CardBody>
               

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <p>
                      Date of Birth <span className="icon-danger">*</span>
                    </p>
                    <InputGroup className="date" id="datetimepicker">
                      <Controller
                        as={ReactDatetime}
                        control={control}
                        isValidDate={valid}
                        dateFormat="YYYY-MM-DD"
                        name="date_of_birth"
                        timeFormat={false}
                        onChange={(e) => handleDateTimeChange(e)}
                      />

                     
                        <InputGroupText>
                          <span className="glyphicon glyphicon-calendar">
                            <i className="fa fa-calendar" />
                          </span>
                        </InputGroupText>
                     
                    </InputGroup>

                    <p className="text-danger small">
                      {errors.date_of_birth && (
                        <span>{errors.date_of_birth.message}</span>
                      )}
                    </p>
                  </FormGroup>

                  {/* <FormGroup>
              <p>
                SIN <span className="icon-secondary">(optional)</span>
              </p>
              <Input name="sin" className="border-input" placeholder="SSN" />
            </FormGroup> */}
                  {/* <FormGroup
              style={{
                borderColor: "black",
                borderRadius: "5px",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              <SignatureCanvas
                penColor="green"
                canvasProps={{className: "sigPad"}}
                ref={sigPad}
              />
              ,
            </FormGroup>
            {trimmedDataURL
        ? <img className={styles.sigImage}
        alt="signature"
          src={trimmedDataURL} />
        : null}

            <div className="d-flex justify-content-between">
              <div className="p-2">
                <Button
                  className="btn"
                  size="sm"
                  color="secondary"
                  onClick={() => ClearSignature()}
                >
                  Clear
                </Button>
              </div>
              <div className="p-2">
                <Button className="btn" size="sm" 
                color="success"
                onClick={() => AcceptSignature()}>
                  Accept
                </Button>
              </div>
            </div> */}
                  <FormGroup>
                    <p>
                      Full Name <span className="icon-danger">*</span>
                    </p>
                    <Input
                      name="digital_sign"
                      className="border-input"
                      placeholder="Enter Full Name"
                      type="text"
                      innerRef={register({
                        required: "Please enter your full Name",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.digital_sign && (
                        <span>{errors.digital_sign.message}</span>
                      )}
                    </p>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Controller
                        as={<Input type="checkbox" />}
                        name="credit_check"
                        defaultValue={false}
                        value={true}
                        control={control}
                        innerRef={register({
                          required: "Please Check this box",
                        })}
                      />
                      I authorize credit <span className="form-check-sign" />
                    </Label>
                    <p className="text-danger small">
                      {errors.credit_check && (
                        <span>{errors.credit_check.message}</span>
                      )}
                    </p>
                  </FormGroup>

                  <FormGroup check>
                    <Label check>
                      <Controller
                        as={<Input type="checkbox" />}
                        name="second_agree"
                        defaultValue={false}
                        value={true}
                        control={control}
                        innerRef={register({
                          required: "Please Check this box",
                        })}
                      />
                      I agree to terms and conditions{" "}
                      <span className="form-check-sign" />
                    </Label>
                    <p className="text-danger small">
                      {errors.second_agree && (
                        <span>{errors.second_agree.message}</span>
                      )}
                    </p>
                  </FormGroup>

                  <CardFooter>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">
                        <Button
                          className="btn"
                          color="secondary"
                          onClick={GoBack}
                        >
                          Prev
                        </Button>
                      </div>
                      <div className="p-2">
                        <Button className="btn" color="danger" type="submit">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          
          </Col>
          </Row>
        </LargeContainer>
      </Container>
    </>
  );
};

export default CreditCheck;


const LargeContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;

const BouncyDiv = styled.div`
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  // cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center; // left to right
  // border-color: #000000;
  // border-style: solid;
  // border-width: 1px;
  // &:hover {
  //   border-color: #ff0000;
  //   border-style: solid;
  //   border-width: 1px;
  // }
`;