import { lazy } from 'react';

import HomeLayout from "layout/HomeLayout";

import Error404 from "Pages/Error404.js";
import Error422 from "Pages/Error422.js";
import Error500 from "Pages/Error500.js";



import CreditApplication from 'Application';
import Team from "About/Team"
import About from "About/index"

import Transition from "Transition"
// import CreditCenter from "Finance/CreditCenter"
import FinancialService from "Finance/FinancialServices"

import Contact from "Contact/index"
import Warranty from 'About/Warranty';
import PrivacyPolicy from 'Pages/PrivacyPolicy';
import TermsnConditions from 'Pages/TermsnConditions';
// import TipsAdvice from "Finance/index"

// const CustomerFeeback = lazy(() => import("About/CustomerFeeback"))
// const Warranty = lazy(() => import("About/Warranty"))
// const LeaseBuster = lazy(() => import("Promotions/LeaseBuster"))
// const Covid19 = lazy(() => import("Promotions/Covid"))
// const RemotePurchase = lazy(() => import("Promotions/RemotePurchase"))
// const PromotionContact = lazy(() => import("Promotions/Contact"))

const Home = lazy(() => import("Home/index"));
const InventoryList = lazy(() => import("InventoryList/index"));
const Sell = lazy(() => import('Sell'));
const AutoLoanCalculator = lazy(() => import("LoanCalculator"))
const DisplayPage = lazy(() => import("InventoryDetail/DisplayPageFull"))

// const TestDrive = lazy(() => import("TestDrive"))
// // const Application = lazy(() => import("Application/index"));
// const AutoCorp = lazy(() => import("Application/AutoCorp"));
// const Inventory = lazy(() => import("Inventory/index"))
// const InventoryList = lazy(() => import("InventoryList/index"))

// const DisplayPage = lazy(() => import("InventoryDetail/DisplayPageFull"))

// const Terms = lazy(() => import("Pages/TermsnConditions"))
// const Privacy = lazy(() => import("Pages/PrivacyPolicy"))
// const Approved = lazy(() => import("Approved"))

export const routes = [
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "",
        name: "Home Page",
        element: <Home />,
      },
      {
        path: "/animate/*",
        name: "Transition ",
        element: <Transition />,
      },
      {
        path: "/inventory/:id/:slug",
        name: "Inventory",
        element: <DisplayPage />,
      },
      {
        path: "/inventory",
        name: "Inventory",
        element: <InventoryList />,
      },
      {
        path: "/credit-application",
        name: "Credit Application",
        element: <CreditApplication />,
      },
      {
        path: "/sell",
        name: "Sell ",
        element: <Sell />,
      },
      {
        path: "/contact",
        name: "Contact ",
        element: <Contact />,
      },
      {
        path: "/about-us",
        name: "About Us ",
        element: <About />,
      },
      {
        path: "/team",
        name: "Team ",
        element: <Team />,
      },
      {
        path: "/warranty",
        name: "Warranty ",
        element: <Warranty />,
      },
      {
        path: "/loan-calculator",
        name: "Loan Calculator ",
        element: <FinancialService />,
      },
      {
        path: "/auto-loan-calculator",
        name: "Auto Loan Calculator ",
        element: <AutoLoanCalculator />,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy ",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        name: "Terms and Conditions",
        element: <TermsnConditions />,
      },
      {
        path: "/error-404",
        name: "Error 404",
        element: <Error404 />,
      },
      {
        path: "/error-422",
        name: "Error 422",
        element: <Error422 />,
      },
      {
        path: "/error-500",
        name: "Error 500",
        element: <Error500 />,
      },
      {
        path: "*",
        name: "Error 404",
        element: <Error404 />,
      },
    ],

  },


  // {
  //   path: "/privacy-policy",
  //   name: "Privacy Policy",
  //   element: <Privacy />,
  // },
  // {
  //   path: "/terms-and-conditions",
  //   name: "Terms And conditions",
  //   element: <Terms />,
  // },
  // {
  //   path: "/inventory-list",
  //   name: "Inventory",
  //   component: Inventory,
  // },
  // {
  //   path: "/inventory",
  //   name: "Inventory",
  //   component: InventoryList,
  // },
  // {
  //   path: "/used-cars-lower-sackville",
  //   name: "Inventory",
  //   component: InventoryList,
  // },
  // {
  //   path: "/vehicle/:id/:slug?",
  //   name: "VDP",
  //   component: DisplayPage,
  // },
  // {
  //   path: "/credit-application",
  //   name: "Credit Application",
  //   component: AutoCorp,
  // },
  // {
  //   path: "/auto-loan-calculator",
  //   name: "Auto Loan Calculator",
  //   component: AutoLoanCalculator,
  // },
  // {
  //   path: "/lease-buster",
  //   name: "Lease Buster",
  //   component: LeaseBuster,
  // },
  // {
  //   path: "/message",
  //   name: "Approved",
  //   component: Approved,
  // },
  // {
  //   path: "/warranty",
  //   name: "Warranty",
  //   component: Warranty,
  // },
  // {
  //   path: "/covid",
  //   name: "Covid",
  //   component: Covid19,
  // },
  // {
  //   path: "/remote-purchasing",
  //   name: "Remote Purchase",
  //   component: RemotePurchase,
  // },
  // {
  //   path: "/sell",
  //   name: "Sell Car",
  //   component: Sell,
  // },
  // {
  //   path: "/campaign-contact",
  //   name: "Promotion Contact",
  //   component: PromotionContact,
  // },
  // {
  //   path: "/test-drive",
  //   name: "Book a Test Drive",
  //   component: TestDrive,
  // },
  // {
  //   path: "/customer-feeback",
  //   name: "CustomerFeeback",
  //   component: CustomerFeeback,
  // },
  // {
  //   path: "/tips-advice",
  //   name: "Tips and Advice",
  //   component: TipsAdvice,
  // },
  // {
  //   path: "/contact",
  //   name: "Contact",
  //   component: Contact,
  // },
  // {
  //   path: "/credit-center",
  //   name: "Credit Center",
  //   component: CreditCenter,
  // },
  // {
  //   path: "/loan-calculator",
  //   name: "Loan Calculator",
  //   component: FinancialService,
  // },
  // {
  //   path: "/about-us",
  //   name: "About Us",
  //   component: About,
  // },
  // {
  //   path: "/why-choose-us",
  //   name: "Why Choose Us",
  //   component: WhyChooseUs,
  // },
  // {
  //   path: "/team",
  //   name: "Team",
  //   component: Team,
  // },

];

export default routes;
