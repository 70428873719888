import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {
    SEARCH_INVENTORY,
    LOAD_INVENTORY_LIST,
    SG_RESET_ALL_PARAM
    // CLEAR_SEARCH_INVENTORY
} from "constants/actions";

import {
    Form,
    FormControl,
    Button,
    // UncontrolledTooltip,
} from "react-bootstrap";
// import { Formik } from 'formik';
// import * as yup from 'yup';

// const searchschema = yup.object().shape({
//     search: yup.string().required('Enter Search'),
// });

export const SearchInventory = ({setShowCanvas, marginTop}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('')
    const [errorSearch, setErrorSearch] = useState(false)
   
    function SearchSubmit() {
        if (searchQuery?.length === 0 || !searchQuery) {
            setErrorSearch(true)
            return
        }
        setErrorSearch(false)

        dispatch({ type: SG_RESET_ALL_PARAM, payload: null });
        dispatch({ type: SEARCH_INVENTORY, payload: searchQuery });
        dispatch({ type: LOAD_INVENTORY_LIST, payload: `&search=${searchQuery}` });

        navigate(`/inventory?search=${searchQuery}`)
        setShowCanvas(false)
    }

    const { inventory_search } = useSelector(
        (state) => ({
            inventory_search: state.inventories.search,
        }),
        shallowEqual
    );

    function UpdateSearch(e) {
        setErrorSearch(false)
        setSearchQuery(e)
    }
    useEffect(() => {
        setSearchQuery(inventory_search)
    }, [inventory_search]);

    return (
        <>
            <Form className="d-flex" noValidate style={{marginTop: marginTop|| "0px"}}>
                <div className='d-flex flex-column'>
                    <div className='d-flex flex-row'>
                        <FormControl
                            placeholder="search inventory"
                            className="me-2"
                            type="text"
                            name="search"
                            value={searchQuery}
                            onChange={(e) => UpdateSearch(e.target.value)}
                            isInvalid={errorSearch}
                        />
                        <Button onClick={SearchSubmit} 
                        variant="outline-danger">Search</Button>
                    </div>
                    <Form.Control.Feedback type="invalid">
                        {"Please enter search"}
                    </Form.Control.Feedback>
                </div>
            </Form>
        </>
    );
};

export default SearchInventory;

// const schema = yup.object().shape({
//     firstName: yup.string().required(),
//     lastName: yup.string().required(),
//     username: yup.string().required(),
//     city: yup.string().required(),
//     state: yup.string().required(),
//     zip: yup.string().required(),
//     terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
// });

// export function FormExample() {
//     return (
//         <Formik
//             validationSchema={schema}
//             onSubmit={console.log}
//             initialValues={{
//                 firstName: 'Mark',
//                 lastName: 'Otto',
//                 username: '',
//                 city: '',
//                 state: '',
//                 zip: '',
//                 terms: false,
//             }}
//         >
//             {({
//                 handleSubmit,
//                 handleChange,
//                 handleBlur,
//                 values,
//                 touched,
//                 isValid,
//                 errors,
//             }) => (
//                 <Form noValidate onSubmit={handleSubmit}>
//                     <Row className="mb-3">
//                         <Form.Group as={Col} md="4" controlId="validationFormik01">
//                             <Form.Label>First name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="firstName"
//                                 value={values.firstName}
//                                 onChange={handleChange}
//                                 isValid={touched.firstName && !errors.firstName}
//                             />
//                             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//                         </Form.Group>
//                         <Form.Group as={Col} md="4" controlId="validationFormik02">
//                             <Form.Label>Last name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="lastName"
//                                 value={values.lastName}
//                                 onChange={handleChange}
//                                 isValid={touched.lastName && !errors.lastName}
//                             />

//                             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//                         </Form.Group>
//                         <Form.Group as={Col} md="4" controlId="validationFormikUsername">
//                             <Form.Label>Username</Form.Label>
//                             <InputGroup hasValidation>
//                                 <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
//                                 <Form.Control
//                                     type="text"
//                                     placeholder="Username"
//                                     aria-describedby="inputGroupPrepend"
//                                     name="username"
//                                     value={values.username}
//                                     onChange={handleChange}
//                                     isInvalid={!!errors.username}
//                                 />
//                                 <Form.Control.Feedback type="invalid">
//                                     {errors.username}
//                                 </Form.Control.Feedback>
//                             </InputGroup>
//                         </Form.Group>
//                     </Row>
//                     <Row className="mb-3">
//                         <Form.Group as={Col} md="6" controlId="validationFormik03">
//                             <Form.Label>City</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 placeholder="City"
//                                 name="city"
//                                 value={values.city}
//                                 onChange={handleChange}
//                                 isInvalid={!!errors.city}
//                             />

//                             <Form.Control.Feedback type="invalid">
//                                 {errors.city}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                         <Form.Group as={Col} md="3" controlId="validationFormik04">
//                             <Form.Label>State</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 placeholder="State"
//                                 name="state"
//                                 value={values.state}
//                                 onChange={handleChange}
//                                 isInvalid={!!errors.state}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 {errors.state}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                         <Form.Group as={Col} md="3" controlId="validationFormik05">
//                             <Form.Label>Zip</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 placeholder="Zip"
//                                 name="zip"
//                                 value={values.zip}
//                                 onChange={handleChange}
//                                 isInvalid={!!errors.zip}
//                             />

//                             <Form.Control.Feedback type="invalid">
//                                 {errors.zip}
//                             </Form.Control.Feedback>
//                         </Form.Group>
//                     </Row>
//                     <Form.Group className="mb-3">
//                         <Form.Check
//                             required
//                             name="terms"
//                             label="Agree to terms and conditions"
//                             onChange={handleChange}
//                             isInvalid={!!errors.terms}
//                             feedback={errors.terms}
//                             feedbackType="invalid"
//                             id="validationFormik0"
//                         />
//                     </Form.Group>
//                     <Button type="submit">Submit form</Button>
//                 </Form>
//             )}
//         </Formik>
//     );
// }