import { combineReducers } from "redux";

// import { reducer as formReducer } from 'redux-form'; 

import imageprofiles from './imageprofile';
import inventories from './inventory';
import inventoryimages from './inventoryimage';
import paginate from './paginate';
import errors from "./errors";
import messages from "./messages";
import employees from "./employees";
import dealers from "./dealers";

import resetpageparams from "./resetpageparams";
import creditapplication from './creditapplication';

import customer from './customer';
import inventoryselect from "./inventoryselect"
import webcontent from './webcontent';
import webheader from './webheader';

import websiteimages from './websiteimages';
import categories from './categories';
// import landingpages from './landingpages';
import leadsource from './leadsource';
import selectyear from "./selectyear"
import selectmake from "./selectmake"
import selectmodel from "./selectmodel"
import selecttrim from "./selecttrim"

import blackbook from "./blackbook"

import webleads from './webleads'
import tradein from './tradein'
import leasebuster from './leasebuster'
import appointment from './appointment'
import application from './application'
// import pagefilter from './_pagefilter'

import contactus from './contactus'
import webcomments from './webcomments'


import caryears from './caryears';
import carmakes from './carmakes';
import carmodels from './carmodels';
import cartrims from './cartrims';
import carcolors from './carcolors';
import carbodytypes from './carbodytypes';
import carkilometersprice from './carkilometersprice';
import yearmake from "./yearmake";
import yearmakemodel from "./yearmakemodel";

import calculator from "./calculator";

export default combineReducers({
    errors,
    messages,
    appointment,
    application,
    contactus,
    creditapplication,
    caryears,
    carmakes,
    carmodels,
    cartrims,
    carcolors,
    carbodytypes,
    carkilometersprice,
    customer,
    categories,
    dealers,
    employees,
    imageprofiles,
    inventories,
    inventoryimages,
    inventoryselect,
    leadsource,
    leasebuster,
    // landingpages,
    paginate,
    resetpageparams,
    blackbook,
    selectyear,
    selectmake,
    selectmodel,
    selecttrim,
    tradein,
    webcomments,
    webcontent,
    webheader,
    websiteimages,
    webleads,
    yearmake,
    yearmakemodel,
    calculator,
});
