import React, { useState } from "react";
import { Row, Col } from "reactstrap";


const Calculate = (props) => {
  const {
    interestValue,
    amountValue,
    yearsValue,
    // downPayment,
    // tradeinValue,
    updateValue,
  } = props

  const [results, setResults] = useState({
    monthlyPayment: 0,
    biWeeklyPayment: 0,
    weeklyPyment: 0,
    totalPayment: '',
    totalInterest: '',
    isResult: false,
  });
  // console.log(props)
  // const [financeValue, setfinanceValue] = useState(23390);
  // React.useEffect(() => {
  //   setfinanceValue(
  //     parseFloat(amountValue) -
  //     (parseFloat(downPayment) + parseFloat(tradeinValue))
  //   );
  // }, [amountValue, tradeinValue, downPayment]);

  React.useEffect(() => {

    const userAmount = Number(amountValue);
    // monthly
    const calculatedInterest = Number(interestValue / 100) / 100 / 12;
    const calculatedPayments = Number(yearsValue) * 12;
    const x = Math.pow(1 + calculatedInterest, calculatedPayments);
    const monthly = (userAmount * x * calculatedInterest) / (x - 1);
    // bi weekly
    const calculatedBiWeekInterest = Number(interestValue / 100) / 100 / 26;
    const calculatedBiWeekPayments = Number(yearsValue) * 26;
    const biX = Math.pow(1 + calculatedBiWeekInterest, calculatedBiWeekPayments);
    const biWeekly= (userAmount *biX * calculatedBiWeekInterest) / (x - 1);
    // weekly
    const calculatedWeekInterest = Number(interestValue / 100) / 100 / 52;
    const calculatedWeekPayments = Number(yearsValue) * 52;
    const wX = Math.pow(1 + calculatedWeekInterest, calculatedWeekPayments);
    const weekly = (userAmount * wX * calculatedWeekInterest) / (x - 1);

    if (isFinite(monthly)) {
      const monthlyPaymentCalculated = monthly.toFixed(2);
      const totalPaymentCalculated = (monthly * calculatedPayments).toFixed(2);
      const totalInterestCalculated = (monthly * calculatedPayments - userAmount).toFixed(2);

      // console.log("monthlyPaymentCalculated", monthlyPaymentCalculated)
      // console.log("totalPaymentCalculated", totalPaymentCalculated)
      // console.log("totalInterestCalculated", totalInterestCalculated)

      // console.log("biWeekly", biWeekly)
      // console.log("biWeekly", weekly)

      //     // Set up results to the state to be displayed to the user
          setResults({
            monthlyPayment: monthlyPaymentCalculated,
            biWeeklyPayment: biWeekly,
            weeklyPyment: weekly,
            totalPayment: totalPaymentCalculated,
            totalInterest: totalInterestCalculated,
            isResult: true,
          });
    }
    //   return;

  }, [amountValue, interestValue, yearsValue, updateValue]);

  
  return (
    <Row>
      <Col xs="4">
        <div className="card-stats text-center">
          <span className="value-highlight">
            ${Math.round(results?.weeklyPyment)}
          </span>
          <br />
          <small> / Weekly</small>
        </div>
      </Col>
      <Col xs="4">
        <div className="card-stats text-center">
          <span className="value-highlight">
            ${Math.round(results?.biWeeklyPayment)}
          </span>
          <br />
          <small> / Bi-Weekly</small>
        </div>
      </Col>
      <Col xs="4">
        <div className="card-stats text-center">
          <span className="value-highlight">
            ${Math.round(results?.monthlyPayment)}
          </span>
          <br />
          <small> / Monthly</small>
        </div>
      </Col>
    </Row>
  );
};

export default Calculate;
