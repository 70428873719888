import React from "react";

// reactstrap components
import {
 
  Card,


} from "react-bootstrap";


const Thanks = () => {
  return (
    <>
      <Card className="text-dark card-plain ">
        <Card.Body>
          <Card.Title className="text-center" tag="h3">
            Thank You for Submitting
          </Card.Title>
        </Card.Body>
      </Card>
    </>
  );
};

export default Thanks;
