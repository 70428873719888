import { GET_CREDITAPPLICATIONS, FETCH_CREDITAPPLICATION, DELETE_CREDITAPPLICATION, ADD_CREDITAPPLICATION, 
    EDIT_CREDITAPPLICATION, CLEAR_CREDITAPPLICATION, CLEAR_ALL,
    GET_CREDITAPPLICATIONS_PENDING } from "constants/actions";

const initialState = {
    creditapplications: [],
    pending: false
};

export default function CreditApplication (state = initialState, action) {
    switch (action.type) {
        case GET_CREDITAPPLICATIONS_PENDING:
            return {
                ...state,
                pending: true
            }
        case GET_CREDITAPPLICATIONS:
            return {
                ...state,
                creditapplications: action.payload,
                pending: false
            };
        case FETCH_CREDITAPPLICATION:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CREDITAPPLICATION:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CREDITAPPLICATION:
            return {
                ...state,
                creditapplications: state.creditapplications.filter(dealer => dealer.id !== action.payload)
            };
        case ADD_CREDITAPPLICATION:
            return {
                ...state,
                creditapplications: [...state.creditapplications, action.payload]
            };
        case CLEAR_CREDITAPPLICATION:
            return {
                ...state,
                creditapplications: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                creditapplications: []
            };
        default:
            return state;
    }
}

