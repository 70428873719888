import React from "react";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import routes from "Routes";
import { prodStore } from "store";
import ErrorBoundary from "utilities/ErrorBoundary";
import { FullPageLoader } from "utilities/Loaders";
import "./App.css"

const App = () => {
  const routing = useRoutes(routes);
  return (
    <Provider store={prodStore}>
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <div>{routing}</div>
        </React.Suspense>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
