import { ADD_WEB_COMMENT, GET_WEB_COMMENTS, CLEAR_ALL } from "constants/actions";

const initialState = {
    webcomments: [],
    created: []
};

export default function WebComments (state = initialState, action) {
    switch (action.type) {
        case GET_WEB_COMMENTS:
            return {
                ...state,
                webcomments: action.payload
            };
        case ADD_WEB_COMMENT:
            return {
                ...state,
                created: action.payload
            };
       
        case CLEAR_ALL:
            return {
                ...state,
                webcomments: []
            };
        default:
            return state;
    }
}

