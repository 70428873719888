import { GET_CARYEARS, DELETE_CARYEAR, ADD_CARYEAR, 
    FETCH_CARYEAR, EDIT_CARYEAR, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    caryears: []
};

export default function CarYears (state = initialState, action) {
    switch (action.type) {
        case GET_CARYEARS:
            return {
                ...state,
                caryears: action.payload
            };
        case FETCH_CARYEAR:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CARYEAR:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CARYEAR:
            return {
                ...state,
                caryears: state.caryears.filter(CarYear => CarYear.id !== action.payload)
            };
        case ADD_CARYEAR:
            return {
                ...state,
                caryears: [...state.caryears, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                caryears: []
            };
        default:
            return state;
    }
}

