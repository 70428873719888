import React from "react";

// reactstrap components
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { InstagramIcon } from "utilities/SocialIcons";

import { FacebookIcon, TwitterIcon, YouTubeIcon } from "utilities/SocialIcons";

import config from "_config";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <>
        <hr />
        <footer className="footer pt-5 footer-big">
          <Container>
            <Row>
              <Col
                className="text-center ml-auto mr-auto"
                md="2"
                sm="3"
                xs="12"
              >
                <Link to="/">
                  <img
                    src={require("assets/images/logo/companyLogo.png")}
                    alt={config.company}
                    width="150px"
                  />
                </Link>
                <div className="social-area d-flex justify-content-around">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/davefordeautosales/">
                    <i style={{ color: 'blue' }}>
                      <FacebookIcon width='30px' height='30px' />
                    </i>
                  </a>

                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/KingstonAuto/">
                    <i style={{ color: 'blue' }}>
                      <TwitterIcon width='30px' height='30px' />
                    </i>
                  </a>

                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCdJ0-YCwXlxg7fvCMkklF7g/">
                    <i style={{ color: 'blue' }}>
                      <YouTubeIcon width='30px' height='30px' />
                    </i>
                  </a>

                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/davefordeauto/">
                    <i style={{ color: 'blue' }}>
                      <InstagramIcon width='30px' height='30px' />
                    </i>
                  </a>

                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="9" sm="9" xs="12">
                <Row>
                  <Col md="3" sm="3" xs="6">
                    <div className="links">
                      <ul className="uppercase-links stacked-links">
                        <li>
                          <Link to="/inventory">Inventory</Link>
                        </li>
                        <li>
                          <Link to="/sell">Cash for Car</Link>
                        </li>
                        <li>
                          <Link to="/credit-application">Finance</Link>
                        </li>

                        <li>
                          <Link to="/auto-loan-calculator">Calculator</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col md="3" sm="3" xs="6">
                    <div className="links">
                      <ul className="uppercase-links stacked-links">
                        <li>
                          <Link to="/about-us">About</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col md="6" sm="12" xs="12">
                    <div className="links">
                      <ul className="stacked-links">
                        <li>
                          <h3>{config.company}</h3>
                          <h6>
                          
                            {config.address},{config.city}
                           
                            
                            <br />
                            {config.province} {config.pincode}
                          </h6>
                        </li>

                        <li>
                          <h6>Phone: {config.phoneformat}</h6>
                        </li>
                        {/* <li>
                          <h5 className="toll-free">Toll Free {config.tollfreeformat}</h5>
                        </li> */}
                      </ul>
                    </div>
                  </Col>
                </Row>
                <hr />
                <div className="mb-3 d-flex justify-content-end links-last-row">
                  <div className="p-2">
                    © {new Date().getFullYear()} {config.company}
                  </div>
                  <div className="p-2">
                    <a
                      href="https://www.preautoco.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Powered by PreAutoCo.com
                    </a>
                  </div>
                  
                  <div className="p-2">
                    <Link to="/terms-and-conditions">
                      Terms and Conditions
                    </Link>
                  </div>
                  <div className="p-2">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    </>
  );
};

export default Footer;
