import {
    CALCULATOR_DEFAULTS,
    UPDATE_CALCULATOR,
    CALCULATOR_RESULTS,
    CLEAR_CALCULATOR,
    CLEAR_ALL
} from "constants/actions";

const initialState = {
    vehicle: null,
    id: null,
    vehicleAmount: 0,
    amountValue: 23000,
    downPayment: 0,
    interestValue: 599,
    tradeinValue: 0,
    yearsValue: 7,
    salesTaxPercent: 1500,
    salesTaxValue: 23000 * (1500/100),
    otherLevies: 0,
};

export default function Calculator (state = initialState, action) {
    switch (action.type) {
        case CALCULATOR_DEFAULTS:
            return {
                ...state,
            };
        case UPDATE_CALCULATOR:
            return {
                ...state,
                amountValue: Number(action.payload),
            };
        case CALCULATOR_RESULTS:
            // console.log(action.payload)
            return {
                ...state,
                ...action.payload
            };

        case CLEAR_CALCULATOR:
            return {
                ...state,
                vehicle: '',
                amountValue: 0,
                downPayment: 0,
                interestValue: 0,
                tradeinValue: 0,
                yearsValue: 0
                ,
            };
        case CLEAR_ALL:
            return {
                ...state,
                vehicle: '',
                amountValue: 0,
                downPayment: 0,
                interestValue: 0,
                tradeinValue: 0,
                yearsValue: 0

            };
        default:
            return state;
    }
}