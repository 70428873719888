import React, { useState, useEffect, Suspense, useTransition } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// reactstrap components
import {
  Container,
  Row,
} from "react-bootstrap";

import {
  LOAD_PRICED_INVENTORY,
  LOAD_MAKE_INVENTORY,
  LOAD_YEAR_INVENTORY,
  LOAD_MODEL_INVENTORY,
} from "constants/actions";

import Shuffle from "utilities/Shuffle"
import CarCardFixed from "./CarCardFixed"
import "./list.css"
import { LoaderCircle } from "utilities/Loaders"



export const CarCardsSimilarPriced = ({ id, query }) => {
  const dispatch = useDispatch();

  // &price-low=25000&price-high=30000
  // &years=2021
  // &makes=Acura
  const [pageParams, setPageParams] = useState('&limit=4')
  const [inventoryList, setInventoryList] = useState([]);

  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    setPageParams(`&price-low=${Number(query) - 2000}&price-high=${Number(query) + 2000}`)
  }, [query]);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if (query) {
      dispatch({ type: LOAD_PRICED_INVENTORY, payload: pageParams });
    }

  }, [dispatch, pageParams, query]);

  const { get_inventory_list } = useSelector(
    (state) => ({
      get_inventory_list: state.inventories.inventoryPricedlist
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_inventory_list) {
      const _preList = Shuffle(get_inventory_list.filter(
        st => st.status === 4))
      startTransition(() => {
        setInventoryList(_preList.filter(p => p.id !== id).slice(0, 4));
      });
    }
  }, [get_inventory_list, id]);

  if (inventoryList.length === 0) {
    return null
  }

  return (
    <>
      <div className="pb-5">

        <Container>
          <h3>Similar Priced Cars <small>(+/- CA$ 2000)</small></h3>
          <div style={{ opacity: isPending ? 0.8 : 1 }}>
          <Row >
            {inventoryList && inventoryList.map((p, k) => (
              <Suspense fallback={LoaderCircle} key={k}>
                  <CarCardFixed _p={p} key={k} />
              </Suspense>

            ))}
          </Row>
          </div>

        </Container>
      </div>
    </>
  );
};

export default CarCardsSimilarPriced;


export const CarCardsSimilarMake = ({ id, query }) => {
  const dispatch = useDispatch();

  // &price-low=25000&price-high=30000
  // &years=2021
  // &makes=Acura
  const [pageParams, setPageParams] = useState('&limit=4')
  const [inventoryList, setInventoryList] = useState([]);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    setPageParams(`&makes=${query}`)
  }, [query]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (query) {
      dispatch({ type: LOAD_MAKE_INVENTORY, payload: pageParams });
    }

  }, [dispatch, pageParams, query]);

  const { get_inventory_list } = useSelector(
    (state) => ({
      get_inventory_list: state.inventories.inventoryMakelist
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_inventory_list) {
      const _preList = Shuffle(get_inventory_list.filter(
        st => st.status === 4))
      startTransition(() => {
        setInventoryList(_preList.filter(p => p.id !== id).slice(0, 4));
      });
    }
  }, [get_inventory_list, id]);

  if (inventoryList.length === 0) {
    return null
  }

  return (
    <>
      <div className="pb-5">

        <Container>
          <h3>More {query}'s</h3>
          <div style={{ opacity: isPending ? 0.8 : 1 }}>
          <Row >
            {inventoryList && inventoryList.map((p, k) => (
              <Suspense fallback={LoaderCircle} key={k}>
                  <CarCardFixed _p={p} key={k} />
              </Suspense>

            ))}
          </Row>
          </div>
        </Container>
      </div>
    </>
  );
};


export const CarCardsSimilarModel = ({ id, query }) => {
  const dispatch = useDispatch();

  // &price-low=25000&price-high=30000
  // &years=2021
  // &makes=Acura
  const [pageParams, setPageParams] = useState('&limit=4')
  const [inventoryList, setInventoryList] = useState([]);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    setPageParams(`&models=${query}`)
  }, [query]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (query) {
      dispatch({ type: LOAD_MODEL_INVENTORY, payload: pageParams });
    }

  }, [dispatch, pageParams, query]);

  const { get_inventory_list } = useSelector(
    (state) => ({
      get_inventory_list: state.inventories.inventoryModellist
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_inventory_list) {
      const _preList = Shuffle(get_inventory_list.filter(
        st => st.status === 4))
      startTransition(() => {
        setInventoryList(_preList.filter(p => p.id !== id).slice(0, 4));
      });
    }
  }, [get_inventory_list, id]);


  if (inventoryList.length === 0) {
    return null
  }

  return (
    <>
      <div className="pb-5">

        <Container>
          <h3>More {query}'s</h3>
          
          <div style={{ opacity: isPending ? 0.8 : 1 }}>
          <Row >
            {inventoryList && inventoryList.map((p, k) => (
              <Suspense fallback={LoaderCircle} key={k}>
                  <CarCardFixed _p={p} key={k} />
              </Suspense>

            ))}
          </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export const CarCardsSimilarYear = ({ id, query }) => {
  const dispatch = useDispatch();

  // &price-low=25000&price-high=30000
  // &years=2021
  // &makes=Acura
  const [pageParams, setPageParams] = useState('&limit=4')
  const [inventoryList, setInventoryList] = useState([]);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    setPageParams(`&years=${query}`)
  }, [query]);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if (query) {
      dispatch({ type: LOAD_YEAR_INVENTORY, payload: pageParams });
    }

  }, [dispatch, pageParams, query]);

  const { get_inventory_list } = useSelector(
    (state) => ({
      get_inventory_list: state.inventories.inventoryYearlist
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_inventory_list) {
      const _preList = Shuffle(get_inventory_list.filter(
        st => st.status === 4))
      startTransition(() => {
        setInventoryList(_preList.filter(p => p.id !== id).slice(0, 4));
      });
    }
  }, [get_inventory_list, id]);


  return (
    <>
      <div className="pb-5">

        <Container>
          <h3>More Year {query} Vehicles</h3>
          <div style={{ opacity: isPending ? 0.8 : 1 }}>
          <Row>
            {inventoryList && inventoryList.map((p, k) => (
              <Suspense fallback={LoaderCircle} key={k}>
                  <CarCardFixed _p={p} key={k} />
              </Suspense>

            ))}
          </Row>
          </div>

        </Container>
      </div>
    </>
  );
};