import React, {
  // useState 
} from 'react';
import { Outlet } from "react-router-dom";

import Footer from './Footer';
import NavBar from './NavBar';

import "./style.css";

const HomeLayout = () => {
  // const [canvasOpen, setCanvasOpen] = useState(false)
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>

  );
};

export default HomeLayout;

