import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
// reactstrap components
import { Container, Row, Col, Badge } from "reactstrap";

import { fadeInLeft, bounce } from "react-animations";

import HeadShake from "react-reveal/HeadShake";
import Tada from "react-reveal/Tada";
import inventory from "reducers/inventory";

const BouncyDiv = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;

const Steps = () => {
  const [counterZero, setCounterZero] = React.useState(1);
  const [counterOne, setCounterOne] = React.useState(1);
  const [counterTwo, setCounterTwo] = React.useState(1);
  const [counterThree, setCounterThree] = React.useState(1);
  const [constSet, setConstSet] = React.useState(true);

  React.useEffect(() => {
    let Random = Math.random();
    let mounted = true;
    if (mounted) {
      if (constSet) {
        setConstSet(false);
        setTimeout(() => {
          setCounterZero(Random);
        }, 3 * 1000);
        setTimeout(() => {
          setCounterOne(Random);
        }, 5 * 1000);
        setTimeout(() => {
          setCounterTwo(Random);
        }, 6 * 1000);
        setTimeout(() => {
          setCounterThree(Random);
          Random = Math.random();
          setConstSet(true);
        }, 7 * 1000);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [constSet]);

  return (
    <>
      <Container fluid style={{ backgroundColor: "#cdcdcd" }}>
        <Container className="pt-4 pb-5">
          <Row className="row-grid justify-content-center mt-3">
            <Col className="text-center" lg="8">
              {" "}
              <Tada spy={counterZero}>
                {" "}
                <h2 className="display-3">
                  <span className="text-success">3 Simple Steps</span>
                </h2>
              </Tada>
              <p className="lead">
                Get a vehicle of your choice with simple application and our in
                house team will help you get you approved from one of the many
                lenders and locate the perfect vehicle.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col lg="12">
              <Row>
                <Col lg="4">
                  <HeadShake spy={counterOne}>
                    <BouncyDiv>
                      <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                        <i className="ni ni-check-bold" />
                      </div>
                      <h4 className="h3 text-success text-uppercase text-center">
                        Apply
                      </h4>
                      <p className="description mt-3">
                        Fill in our credit application. It's a simple and easy
                        form with details to get an approval
                      </p>
                    </BouncyDiv>
                  </HeadShake>
                </Col>
                <Col lg="4">
                  <HeadShake spy={counterTwo}>
                    <BouncyDiv>
                      <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                        <i className="ni ni-check-bold" />
                      </div>
                      <h4 className="h3 text-success text-uppercase text-center">
                        Approve
                      </h4>
                      <p className="description mt-3">
                        We would work with various lenders and get the right
                        loan from the right lender.
                      </p>
                    </BouncyDiv>
                  </HeadShake>
                </Col>
                <Col lg="4">
                  <HeadShake spy={counterThree}>
                    {" "}
                    <BouncyDiv>
                      <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                        <i className="ni ni-check-bold" />
                      </div>
                      <h4 className="h3 text-success text-uppercase text-center">
                        Drive
                      </h4>
                      <p className="description mt-3">
                        Pick a vehicle of your choice from our{" "}
                        <Link to="/inventory">
                          <Badge color="danger" style={{ cursor: "pointer" }}>
                            inventory
                          </Badge>
                        </Link>
                        and drive your car away.
                      </p>
                    </BouncyDiv>
                  </HeadShake>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Steps;
