import React from "react";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import config from "_config";

const images = [
  {
    src: "lender-logo-auto-capital.jpg",
    name: "Auto Capital",
    alt: "lender-logo-auto-capital.jpg",
  },
  {
    src: "lender-logo-carfinco.jpg",
    name: "Auto Capital",
    alt: "lender-logo-carfinco.jpg",
  },
  {
    src: "lender-logo-cars-on-credit.jpg",
    name: "Auto Capital",
    alt: "lender-logo-cars-on-credit.jpg",
  },
  {
    src: "lender-logo-credit-unions-atlantic-canada.jpg",
    name: "Auto Capital",
    alt: "lender-logo-credit-unions-atlantic-canada.jpg",
  },
  {
    src: "lender-logo-ctl.jpg",
    name: "Auto Capital",
    alt: "lender-logo-ctl.jpg",
  },
  {
    src: "lender-logo-eden-park.jpg",
    name: "Auto Capital",
    alt: "lender-logo-eden-park.jpg",
  },
  {
    src: "lender-logo-ggr.jpg",
    name: "Auto Capital",
    alt: "lender-logo-ggr.jpg",
  },
  {
    src: "lender-logo-industrial-alliance.jpg",
    name: "Auto Capital",
    alt: "lender-logo-industrial-alliance.jpg",
  },
  {
    src: "lender-logo-lendcare.jpg",
    name: "Auto Capital",
    alt: "lender-logo-lendcare.jpg",
  },
  {
    src: "lender-logo-national-bank.jpg",
    name: "Auto Capital",
    alt: "lender-logo-national-bank.jpg",
  },
  {
    src: "lender-logo-newstart.jpg",
    name: "Auto Capital",
    alt: "lender-logo-newstart.jpg",
  },
  {
    src: "lender-logo-rbc.jpg",
    name: "Auto Capital",
    alt: "lender-logo-rbc.jpg",
  },
  {
    src: "lender-logo-rifco.jpg",
    name: "Auto Capital",
    alt: "lender-logo-rifco.jpg",
  },
  {
    src: "lender-logo-scotia-dealer-advantage.jpg",
    name: "Auto Capital",
    alt: "lender-logo-scotia-dealer-advantage.jpg",
  },
  {
    src: "lender-logo-source-finacial.jpg",
    name: "Source Finacial",
    alt: "lender-logo-source-finacial.jpg",
  },
  { src: "lender-logo-td.jpg", name: "TD", alt: "lender-logo-td.jpg" },
];

const LenderImages = () => {
  return (
    <>
      <div className="text-center">
        <h4 className="display-4 mb-5 mt-5">We work with many Lenders</h4>

        <Row className="justify-content-center">
          {images.map(function (imageProps, i) {
            return (
              
                <Col
                  className="my-2"
                  md="2"
                  xs="3"
                  key={i}
                  id={`tooltip-${i}`}
                >
                  <img
                    alt={imageProps.alt}
                    className="img-fluid"
                    src={require(`assets/images/lenders/${imageProps.src}`)}
                  />
                  {imageProps.src ? (
                    <UncontrolledTooltip delay={0} target={`tooltip-${i}`}>
                      {imageProps.name}
                    </UncontrolledTooltip>
                  ) : (
                    ""
                  )}
                </Col>
           
            );
          })}
        </Row>
        <p className="lead">
          We work with various financial instituions to get the loan approvals.
          Get approved with as low as $0 down payment. Qualify for the loan
          before or after you choose a vehicle and without any commitments or
          credit checks. Terms and condtions apply
        </p>
        <p>
          Your data is important to Dave Forde Auto Sales. We utilize the
          highest SSL Technology available for secure online communications to
          encrypt and protect your financial data.
        </p>
      </div>
    </>
  );
};

export default LenderImages;
