import React from "react";
import styled, { keyframes } from "styled-components";
// react library for routing
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

import { Link } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

import { fadeInLeft, bounce } from "react-animations";

const LargeContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;

const BouncyDiv = styled.div`
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  // cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center; // left to right
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  &:hover {
    // animation: 2s ${keyframes`${bounce}`} infinite;
    border-color: #ff0000;
    border-style: solid;
    border-width: 1px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between; // left to right
`;
const MainContent = styled.div`
  padding: 5px;
  // min-height: 60px;
  background-color: #ffffff;
  margin: 5px;
  font-size: large;
`;

const Budget = ({ setDirection, setInSection, application }) => {
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    // console.log(data);
    dispatch({
      type: SG_UPDATE_FINANCE_APPLICATION,
      payload: {
        budget: e,
      },
    });

    setInSection(3);
    // MakeContactVisible(2)
  };

  function GoBack() {
    setDirection(false);
    setInSection(1);
  }

  const [selected, setSelected] = React.useState(0);

  React.useEffect(() => {
    if (application?.budget) {
      switch (application?.budget) {
        case "Less than $250":
          return setSelected(1);
        case "$250 to $375":
          return setSelected(2);
        case "$375 to $500":
          return setSelected(3);
        case "500+":
          return setSelected(4);
        default:
          setSelected(0);
      }
    }
  }, [application]);

  return (
    <>
      <Container fluid>
        <LargeContainer>
          <Button close onClick={GoBack} />
          <Row className="justify-content-center text-center">
            <Col md="6">
              <h2 className="display-4">
                <span style={{ cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                    onClick={GoBack}
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>{" "}
                </span>
                Monthly Budget
              </h2>

              <p>Relax! Take some time into researching your finances.</p>
            </Col>
          </Row>

          <div className="fadeInLeft">
            <Row className="align-items-center">
              <Col lg="12">
                <Row className="pt-5 ">
                  <Col md="3">
                    <BouncyDiv
                      onMouseOver={() => setSelected(1)}
                      onMouseLeave={() => setSelected(0)}
                      onClick={() => onSubmit("Less than $250")}
                    >
                      <h3 className="h3 text-center mb-4">Less than $250</h3>
                      <MainContent className="lead text-center mb-4">
                        This would be the ideal budget for entry level cars or
                        very basic cars to get you moving.
                      </MainContent>
                      <Button
                        color={selected === 1 ? "danger" : "secondary"}
                        onClick={() => onSubmit("Less than $250")}
                      >
                        Select
                      </Button>
                    </BouncyDiv>
                  </Col>
                  <Col md="3">
                    <BouncyDiv
                    onClick={() => onSubmit("$250 to $375")}
                      onMouseOver={() => setSelected(2)}
                      onMouseLeave={() => setSelected(0)}
                    >
                      <h3 className="h3 text-center mb-4">$250 to $375 </h3>

                      <MainContent className="lead text-center mb-4">
                        This is a good budget for finding some in more recent
                        cars and some good features as standard.
                      </MainContent>

                      <Button
                        color={selected === 2 ? "danger" : "secondary"}
                        onClick={() => onSubmit("$250 to $375")}
                      >
                        Select
                      </Button>
                    </BouncyDiv>
                  </Col>
                  <Col md="3">
                    <BouncyDiv
                      onMouseOver={() => setSelected(3)}
                      onMouseLeave={() => setSelected(0)}
                      onClick={() => onSubmit("$375 to $500")}
                    >
                      <h3 className="h3 text-center mb-4">$375 to $500</h3>
                      <MainContent className="lead text-center mb-4">
                        This budget your choices get more wider with cars and
                        you can't really go wrong
                      </MainContent>
                      <Button
                        color={selected === 3 ? "danger" : "secondary"}
                        onClick={() => onSubmit("$375 to $500")}
                      >
                        Select
                      </Button>
                    </BouncyDiv>
                  </Col>
                  <Col md="3">
                    <BouncyDiv
                      onMouseOver={() => setSelected(4)}
                      onMouseLeave={() => setSelected(0)}
                      onClick={() => onSubmit("$500+")}
                    >
                      <h3 className="h3 text-center mb-4">500+</h3>
                      <MainContent className="lead text-center mb-4">
                        You are not looking to compromise, this would be best
                        choice where options open up.
                      </MainContent>
                      <Button
                        color={selected === 4 ? "danger" : "secondary"}
                        onClick={() => onSubmit("$500+")}
                      >
                        Select
                      </Button>
                    </BouncyDiv>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </LargeContainer>
      </Container>
    </>
  );
};

export default Budget;
