import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

const Promotions = () => {
  return (
    <>
      <div className="pt-2 promotion-box">
        <Container>
          <Row>
            <Col>
              <h2 className="text-left">
                <span className="text-thick-headline promotion-blue">
                Outstanding Customer Service
                </span>
              </h2>
              <p className="text-left  mt-1">
                
                  Read reviews from previous customers as they share their 
                  professional, stress-free car buying experiences. 
                  Read how they made the right decision on where to get 
                  their next pre-owned vehicle.
                 
                
              </p>
            </Col>
          </Row>
          
          <Row>
            <Col className="ml-auto mr-auto mb-4" md="12">
              <Row className="mt-3 no-gutters">
                
                <Col md="3" sm="12">
                  <div className="showme ">
                    <Link to={"/customer-feeback"}>
                        <div className="background-fill-red ">
                          <h6 className="text-center">Read Reviews</h6>
                        </div>
                    </Link>
                  </div>
                </Col>

                
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Promotions;
