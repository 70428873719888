import { 
 GET_MODEL_SELECT, 
    FETCH_MODEL_SELECT,
    CLEAR_ALL } from "constants/actions";
const initialState = {
    modelselect: [],
    modelSelected: []
};

export default function SelectModel (state = initialState, action) {
    switch (action.type) {
        case GET_MODEL_SELECT:
            return {
                ...state,
                modelselect: action.payload
            };
        case FETCH_MODEL_SELECT:
            return { ...state, modelSelected: action.payload };
        case CLEAR_ALL:
            return {
                ...state,
                modelselect: []
            };
        default:
            return state;
    }
}

